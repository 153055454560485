import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faUserSecret } from "@fortawesome/free-solid-svg-icons";
import "./PillButton.css";

/**

### Props
- handleInput(required): function from parent called whenever vote type changes
- className(optional): classes that apply to the wrapper div of the object
- style(optional): styles that apply to the wrapper div of the object

### Example
```jsx
const [options, setOptions] = useState({});
// ... 
<PillButton handleInput={setOptions}/>
```

### Balot Schema
```json
{
    "title": "string",
    "question": "string",
    "options": ["string"],
    "type": "type in ['secret','open']"
}
```

### Responsibilities
- title: Parent;
- question: Parent;
- options: Parent;
- type: Child(this);


*/

const PillButton = ({ className, style, handleInput }) => {
    const [selectedButton, setSelectedButton] = useState(null);

    const handleButtonClick = (buttonName) => {
        if (selectedButton === buttonName) {
            setSelectedButton(null);
        } else {
            setSelectedButton(buttonName);
        }
    };

    const handleVotDeschisClick = () => {
        handleInput((prev) => {
            let next = prev;
            next.type = "public";
            return JSON.parse(JSON.stringify(next));
        })
    };

    const handleVotSecretClick = () => {
        handleInput((prev) => {
            let next = prev;
            next.type = "secret";
            return JSON.parse(JSON.stringify(next));
        })
    };

    return (
        <div className={`pillButton ${className}`} style={style}>
            <button
                className={`unpushedButton borderLeftButton ${
                    selectedButton === "left" ? "pushedButton" : ""
                }`}
                onClick={() => {
                    handleButtonClick("left");
                    handleVotDeschisClick();
                }}
            >
                <FontAwesomeIcon
                    className={`unpushedButtonIcon ${
                        selectedButton === "left" ? "pushedButtonIcon" : ""
                    }`}
                    icon={faEye}
                />
                <span
                    className={`unpushedButtonLabel ${
                        selectedButton === "left" ? "pushedButtonLabel" : ""
                    }`}
                >
                    Vot deschis
                </span>
            </button>
            <button
                className={`unpushedButton borderRightButton ${
                    selectedButton === "right" ? "pushedButton" : ""
                }`}
                onClick={() => {
                    handleButtonClick("right");
                    handleVotSecretClick();
                }}
            >
                <FontAwesomeIcon
                    className={`unpushedButtonIcon ${
                        selectedButton === "right" ? "pushedButtonIcon" : ""
                    }`}
                    icon={faUserSecret}
                />
                <span
                    className={`unpushedButtonLabel ${
                        selectedButton === "right" ? "pushedButtonLabel" : ""
                    }`}
                >
                    Vot secret
                </span>
            </button>
        </div>
    );
};

export default PillButton;
