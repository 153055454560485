import React from "react";

import { UserAuth } from "../context/AuthContext";
import VotingCard from "../components/Voting/VotingCard/VotingCard";

import "./Vote.css";

const Vote = ({ballot}) => {
  const { user } = UserAuth();
  const myAuthToken =
    `Bearer ${user.accessToken}`;

    

  return (
    <div className="votingContainer">
      {ballot && <VotingCard ballot={ballot} authToken={myAuthToken}/>}
    </div>
  );
};

export default Vote;
