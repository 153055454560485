import  React from 'react';
import './errorpage.css'; 

const  EroarePage = (props) => {
    return (
        <div className='Eroare'>
            <img src={props.img} alt="Eroare 404" />
            <h1 className='Eroaretext'>{props.text}</h1>

        </div>
    )
}

export default EroarePage



