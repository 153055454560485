import React from "react";

import { UserAuth } from "../context/AuthContext";

import "./Results.css";
import HeaderElement from "../components/HeaderElement/HeaderElement";
import VoteType from "../components/Voting/VotingCard/VoteType/VoteType";
import ResultsCard from "../components/Voting/ResultsCard/ResultsCard";
import BarChartIcon from "@mui/icons-material/BarChart";
import copyIcon from "./copy.svg"

const Results = ({ ballot}) => {
  const { user } = UserAuth();
  // const [ballot, setBallot] = React.useState();
  const [realFinalVotes, setRealFinalVotes] = React.useState([]);
  let finalVotes = [];
  const [total, setTotal] = React.useState(0);
  const [juniorVote, setJuniorVote] = React.useState(-1);
  const [alumniVote, setAlumniVote] = React.useState(-1);
  const [currentSeenVote, setCurrentSeenVote] = React.useState("senior");
  let alumniVotes, juniorVotes;
  const [finalJuniorVotesCount,setFinalJuniorVotesCount] = React.useState(0);
  const [finalAlumniVotesCount, setFinalAlumniVotesCount] = React.useState(0);
  const [finalJuniorVotes, setFinalJuniorVotes] = React.useState();
  const [finalAlumniVotes, setFinalAlumniVotes] = React.useState();
  
  
  React.useMemo(() => {
    const calcVotes = async () => {
      alumniVotes = [];
      juniorVotes = [];
      if (ballot !== undefined) {
        let increment = 0;
        // ballot.votes = ballot.votes.map((vote) => parseInt(vote));
        console.log(ballot);
        if (ballot.type == "secret") {
          ballot.votes.forEach((voteOption) => {
            increment += voteOption;
          });
          finalVotes = ballot.votes;
        } else {
          let alreadyFoundJunior = false,
            alreadyFoundAlumni = false;
          for (let i = 0; i < ballot.votes.length; i++) {
            if (ballot.statutes[i] === "senior") {
              increment++;
            }
            if (ballot.statutes[i] === "junior" && !alreadyFoundJunior) {
              increment++;
              alreadyFoundJunior = true;
            }
            if (ballot.statutes[i] == "alumni" && !alreadyFoundAlumni) {
              increment++;
              alreadyFoundAlumni = true;
            }
          }

          //CALCULATE RESULTS

          let juniorVoteCount = 0,
            alumniVoteCount = 0;
          for (let i = 0; i < ballot.options.length; i++) {
            alumniVotes.push(0);
            juniorVotes.push(0);
            finalVotes.push(0);
          }
          // count votes
          for (let i = 0; i < ballot.votes.length; i++) {
            if (ballot.statutes[i] == "alumni") {
              alumniVotes[ballot.votes[i]]++;
              alumniVoteCount++;
            } else if (ballot.statutes[i] == "junior") {
              juniorVotes[ballot.votes[i]]++;
              juniorVoteCount++;
            } else if (ballot.statutes[i] == "senior") {
              finalVotes[ballot.votes[i]]++;
            }
          }

          // calculate alumni vote
          if (alumniVoteCount != 0) {
            let foundVote = false
            for (let i = 0; i < alumniVotes.length; i++) {
              if (alumniVotes[i] >= Math.floor(alumniVoteCount / 2) + 1) {
                setAlumniVote(i);
                finalVotes[i]++;
                foundVote = true
                break;
              }
            }
            if (!foundVote) {
              setAlumniVote(ballot.options.length - 1);
              finalVotes[ballot.options.length-1]++;
              console.log(finalVotes)
            }
          }
          // calculate junior vote
          if (juniorVoteCount != 0) {
            let foundVote = false
            for (let i = 0; i < juniorVotes.length; i++) {
              if (juniorVotes[i] >= Math.floor(juniorVoteCount / 2) + 1) {
                setJuniorVote(i);
                finalVotes[i]++;
                foundVote = true
                break;
              }
            }
            if (!foundVote) {
              setJuniorVote(ballot.options.length - 1);
              finalVotes[ballot.options.length - 1]++;
            }
          }
          // console.log(finalVotes);
          setFinalJuniorVotesCount(juniorVoteCount)
        setFinalAlumniVotesCount(alumniVoteCount)
        }
        
        setFinalJuniorVotes(juniorVotes);
        setFinalAlumniVotes(alumniVotes);
        setRealFinalVotes(finalVotes);
        setTotal(increment);
      }
    };
    calcVotes();
  }, [ballot]);

  function copyResultsToClipboard() {
    let copyText = `${ballot.title} - ${ballot.createdAt.substring(0,10)} \n${ballot.question} \nVot ${ballot.type} \n`;
    ballot.options.forEach((option,index) => {
      copyText += `${option} : ${realFinalVotes[index]} \n`
    })
    navigator.clipboard.writeText(copyText);
  }
  return (
    <div>
      <div className="resultsPageContainer">
        {ballot && realFinalVotes && (
          <div>
            <HeaderElement headerText={ballot.title} />
            <div className="resultsPageBodyContainer">
              <div className="resultsPageVoteCount">Total voturi: {currentSeenVote == "senior" ? total : currentSeenVote == "junior" ? finalJuniorVotesCount : finalAlumniVotesCount}</div>
              <div className="resultsPageVoteCount">
                Total votanti: {currentSeenVote == "senior" ? ballot.voters.length : currentSeenVote == "junior" ? finalJuniorVotesCount : finalAlumniVotesCount}
                {/* Total votanti: {finalJuniorVotesCount} */}
              </div>
              <VoteType voteType={ballot.type} />
              <div className="resultsPageOptionsContainer">
                {finalJuniorVotes &&
                  finalAlumniVotes &&
                  ballot.options.map((option, index) => {
                    return (
                      <ResultsCard
                        total={currentSeenVote == "senior" ? total : currentSeenVote == "junior" ? finalJuniorVotesCount : finalAlumniVotesCount}
                        name={option}
                        voteCount={
                          currentSeenVote == "senior"
                            ? realFinalVotes[index]
                            : currentSeenVote == "junior"
                            ? finalJuniorVotes[index]
                            : finalAlumniVotes[index]
                        }
                      >
                        {ballot.type == "public" &&
                          ballot.votes.map((vote, index2) => {
                            if (
                              index == vote &&
                              ballot.statutes[index2] == currentSeenVote
                            ) {
                              const firstName =
                                ballot.voters[index2].split(".")[0];
                              const lastName =
                                ballot.voters[index2].split(".")[1];
                              const firstNameFirstLetter = firstName
                                .charAt(0)
                                .toUpperCase();
                              const firstNameRemainingLetters =
                                firstName.slice(1);
                              const firstNameFinal =
                                firstNameFirstLetter +
                                firstNameRemainingLetters;
                              const lastNameFirstLetter = lastName
                                .charAt(0)
                                .toUpperCase();
                              const lastNameRemainingLetters =
                                lastName.slice(1);
                              const lastNameFinal =
                                lastNameFirstLetter + lastNameRemainingLetters;
                              return (
                                <div>
                                  {firstNameFinal + " " + lastNameFinal}
                                </div>
                              );
                            }
                          })}
                        {currentSeenVote == "senior" && juniorVote == index && (
                          <div>Votul Juniorilor</div>
                        )}
                        {currentSeenVote == "senior" && alumniVote == index && (
                          <div>Votul Alumnilor</div>
                        )}
                      </ResultsCard>
                    );
                  })
                  }
              </div>
            </div>
          </div>
        )}
        {currentSeenVote == "senior" && ballot.type != "secret" && (
          <button
            className="voteStatusButton resultsTypeButton"
            onClick={() => {
              setCurrentSeenVote("junior");
            }}
          >
            <BarChartIcon />
            <div>Vezi Votul Juniorilor</div>
          </button>
        )}
        {currentSeenVote == "senior" && ballot.type != "secret" && (
          <button
            className="voteStatusButton resultsTypeButton"
            onClick={() => {
              setCurrentSeenVote("alumni");
            }}
          >
            <BarChartIcon />
            <div>Vezi Votul Alumnilor</div>
          </button>
        )}
        {currentSeenVote != "senior" && ballot.type != "secret" && (
          <button
            className="voteStatusButton resultsTypeButton"
            onClick={() => {
              setCurrentSeenVote("senior");
            }}
          >
            <BarChartIcon />
            <div>Vezi Rezultatul Final</div>
          </button>
        )}
        <button className="copyResultsButton" onClick={copyResultsToClipboard}>
          <img src={copyIcon} alt="copy"/>
            <span>Copiază Rezultatul</span>
        </button>
      </div>
    </div>
  );
};

export default Results;
