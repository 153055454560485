import React from "react";

import "./SendVote.css";

const SendVote = ({ onClickFunction }) => {
  return (
    <button className="sendVoteButton" onClick={onClickFunction}>
      <svg
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_1599_121)">
          <path
            d="M18.1223 4.20547C18.6246 4.64493 18.6246 5.3586 18.1223 5.79805L7.83661 14.7981C7.33438 15.2375 6.51875 15.2375 6.01652 14.7981L0.873661 10.2981C0.371428 9.8586 0.371428 9.14493 0.873661 8.70547C1.37589 8.26602 2.19152 8.26602 2.69375 8.70547L6.92857 12.4074L16.3063 4.20547C16.8085 3.76602 17.6241 3.76602 18.1263 4.20547H18.1223Z"
            fill="#EFE7EB"
          />
        </g>
        <defs>
          <clipPath id="clip0_1599_121">
            <rect
              width="18"
              height="18"
              fill="white"
              transform="translate(0.5 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
      Trimite
    </button>
  );
};

export default SendVote;
