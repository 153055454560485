import React from 'react';
import './Avatar.css';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
/*
props:
    photoURL: the url of the photo of the user
    name: the name of the user
    small: can be true/false. If set to true it is small, otherwise it is big. Defaults to false
    height: is an int, affects the width and height of the image and the size of the text. 40 is default for big and 28 for small
*/

const Avatar = ({photoURL, name,height, small = false, style}) => {

        return (
            <div className="avatarBg" style={style}>
                {photoURL ? <img className={small?"avatarPhoto":"avatarPhotoBig"} src={photoURL} alt={name} style={{height:height+"px",width:height+"px"}}/> : <AccountCircleIcon/>}
                <div className="avatarText" style={{fontSize:Math.floor(height/2)+"px"}}>{name}</div>
            </div>
        );

}

export default Avatar;
