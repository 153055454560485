// import logo from './logo.svg';
// import './App.css';

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import { AuthContextProvider } from './context/AuthContext';
import Protected from './components/Protected';
import Home from './pages/Home';
import UserManagement from './pages/UserManagement';
import Vote from './pages/Vote';
import CreateVote from "./pages/CreateVote"
import EditVote from './pages/EditVote';
import Results from './pages/Results';
import Developer from './pages/Developer';
import IstoricVoturi from './pages/istoricVoturi';
import PublicRouting from './pages/PublicRouting';
import AdminRoutes from './pages/AdminRoutes';
import {createTheme, ThemeProvider} from "@mui/material/styles"
import VoteHistory from './pages/VoteHistory';

const theme = createTheme({
  palette: {
    eestecred: {
      main:"#e52a30"
    }
  }
})

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
      <AuthContextProvider>
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path='/loghin' element={<Login />} />
          <Route path='/' element={
            <Protected>
              <PublicRouting/>
            </Protected>
          } />
          <Route path='/admin' element={
            <Protected>
              <AdminRoutes/>
            </Protected>
          } />
          <Route path='/usermanagement' element={
            <Protected>
              <UserManagement/>
            </Protected>
          } />
          <Route path='/vote' element={
            <Protected>
              <Vote/>
            </Protected>
          } />
          <Route path='/createvote' element={
            <Protected>
              <CreateVote/>
            </Protected>
          } />
          <Route path='/managevote' element={
            <Protected>
              <EditVote/>
            </Protected>
          } />
          <Route path='/results' element={
            <Protected>
              <Results/>
            </Protected>
          } />

          <Route path='/developer' element={
            <Protected>
              <Developer/>
            </Protected>
          } />

          <Route path='/profile' element={
            <Protected>
              
            </Protected>
          } />
      
          <Route path='/votehistory' element={
            <Protected>
              <VoteHistory/>
            </Protected>
          } />
        </Routes>
      </AuthContextProvider>
      </ThemeProvider>
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}


    </div>
  );
}

export default App;
