import React from "react";
import "./LoadingCircle.css";

import { CircularProgress } from "@mui/material";

export default function LoadingCircle() {
  return (
    <div className="loadingCircleCentered">
      <CircularProgress size="5rem" color="eestecred" />
    </div>
  );
}
