import React, { Component, useEffect, useState } from "react";
import "./CreateVote.css";
import strings from "../locales/ro.json"
import PillButton from "../components/Buttons/PillButton/PillButton";
import CreateVoteCard from "../components/Voting/CreateVoteCard/CreateVoteCard";
import HeaderElement from "../components/HeaderElement/HeaderElement";
import { UserAuth } from "../context/AuthContext";
import SendToLCButton from "../components/Buttons/SendToLCButton/SendToLCButton";
import popupicon from "./createVotPopup.svg";
import StandardPopup from "../components/Global/StandardPopup/StandardPopup";
import requestHandler from "../utils/requestHandler";
const CreateVote = () => {
  const questionTitleText = `Titlu...`;
  const { user } = UserAuth();
  const [options, setOptions] = useState({});
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupFunction, setPopupFunction] = useState(() => {});
  const [fillerState, setFillerState] = React.useState("")
  const myAuthToken =
    `Bearer ${user.accessToken}`;
  const abstainText = "Abtinere"

  const ballotData = async () => {
    try {
      // const response = await fetch(
      //   `https://${process.env.REACT_APP_BACKEND_IP}/ballot`,
      //   {
      //     method: "PUT",
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: myAuthToken,
      //     },
      //     body: JSON.stringify({ ...options })
      //   }
      // );
      // console.log(JSON.stringify({ ...options }))
      // const result = await response.json();
      // console.log(result);
      console.log({...options})
      let result = await requestHandler("ballot","PUT",myAuthToken,{...options});
      
      window.location.reload(false)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div>
      <StandardPopup message={strings.createvote.createvotePopup} icon={popupicon} yesFcn={popupFunction} noFcn={() => {setPopupOpen(false)}} isOpen={popupOpen}/>
      <div className="createVotePage">
        <HeaderElement
          inputHandler={setOptions}
          headerText={questionTitleText}
        />
        <CreateVoteCard
          className="createVotePageCard"
          handleOptions={setOptions}
        />
        <PillButton className={`createVotePagePill`} handleInput={setOptions} />
        {options.title &&
        options.question &&
        options.options &&
        options.type ? (
          <SendToLCButton
            className="createVotePageSend"
            onClick={() => {
              setPopupFunction((prevFcn) => {
                return () => {
                  setPopupOpen(false);
                  ballotData();
                  
                };
              });
              setPopupOpen(true);
            }}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default CreateVote;
