import React from 'react'
import "./SearchBar.css"

/*
basic search bar functionality, but styling is hardcoded
 props: 
 handleChange: function that triggers upon change. use this to filter an array or whatever. check UserManagement.js for an example
*/

function SearchBar({handleChange}) {

    const [filter, setFilter] = React.useState('');

    return <input onChange={(event) => {handleChange(event);setFilter(event.target.value)}} value={filter} className='searchBar' placeholder='Cauta...'>
    
    </input>
}

export default SearchBar