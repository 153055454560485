import React from 'react'
import "./StandardPopup.css"

import { Modal } from "@mui/material";

import PopUpResponseButton from '../../Buttons/PopUpResponseButton/PopUpResponseButton';

export default function StandardPopup({isOpen,yesFcn,noFcn,message,message2,icon}) {


    const [open, setOpen] = React.useState(isOpen);

    React.useEffect(() => {
        setOpen(isOpen);
    },[isOpen])


    return <Modal open={open}>
    <div className="popupMembersContainer" data-testid="standardPopup">
      <img src={icon} alt="Sigur?" />
      <div className="popupMembersText">{message}</div>
      <div className="popupMembersText">{message2}</div>
      <div className="popupMembersButtonsContainer">
        <PopUpResponseButton
          type={"yes"}
          text={"Da"}
          onClick={() => {
            if(yesFcn) {
                yesFcn()
            }
            setOpen(false);
          }}
        />
        <PopUpResponseButton
          type={"cancel"}
          text={"Nu"}
          onClick={() => {
            // setIsChanging(false);
            // setPopupOpen(false);
            if(noFcn) {
                noFcn()
            }
            setOpen(false);
        }}
        />
      </div>
    </div>
  </Modal>
}