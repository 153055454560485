import React from "react";
import { useNavigate } from "react-router-dom";
// import { isMobile } from "react-device-detect";
import { useMediaQuery } from "react-responsive";
import { useEffect } from "react";

import logoWhite from "../logo-white.svg";

import { GoogleButton } from "react-google-button";

import { UserAuth } from "../context/AuthContext";

import imagesArray from "../locales/login/images.json";

import "./Login.css";

const Login = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const navigate = useNavigate();

  useEffect(() => {
    // if (isMobile) {
    document.body.classList.add("body-mobile");
    // }
    // else {
    //   document.body.classList.add('body-desktop');
    // }

    return () => {
      document.body.classList.remove("body-mobile");
      // document.body.classList.remove('body-desktop');
    };
  });

  const { googleSignIn, user } = UserAuth();

  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn();
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (user != null) {
      navigate("/");
    }
  }, [user]);

  const randomImageIndex1 = Math.floor(Math.random() * imagesArray.length);
  const randomImageIndex2 = Math.floor(Math.random() * imagesArray.length);
  const randomImageIndex3 = Math.floor(Math.random() * imagesArray.length);

  return (
    <div>
      <p>
        {isMobile ? (
          <div>
            <div className="card">
              <p className="titleCard">
                Welcome to C.O.S.T.E.L Prea bun. Prea ca la tara.
              </p>
              {/* <div>
                <ul className="pozeLista">
                  <li>
                    <img
                      src={imagesArray[randomImageIndex1].link}
                      width="200"
                    />
                    <p>{imagesArray[randomImageIndex1].text}</p>
                  </li>
                </ul>
              </div> */}
              <GoogleButton type="light" onClick={handleGoogleSignIn} />
            </div>
            <img src={logoWhite} className="logoEESTEC" alt="logo" />
          </div>
        ) : (
          <div>
            <div className="card">
              <p className="titleCard">
                Welcome to C.O.S.T.E.L Prea bun. Prea ca la tara.
              </p>
              <GoogleButton type="light" onClick={handleGoogleSignIn} />
            </div>
            <img src={logoWhite} className="logoEESTEC" alt="logo" />
            <div className="ecranAlb">
              <div className="poze">
                <ul className="pozeLista">
                  <li>
                    <img
                      src={imagesArray[randomImageIndex1].link}
                      width="200"
                    />
                    <p>{imagesArray[randomImageIndex1].text}</p>
                  </li>
                  <br />
                  <li>
                    <img
                      src={imagesArray[randomImageIndex2].link}
                      width="200"
                    />
                    <p>{imagesArray[randomImageIndex2].text}</p>
                  </li>
                  <br />
                  <li>
                    <img
                      src={imagesArray[randomImageIndex3].link}
                      width="200"
                    />
                    <p>{imagesArray[randomImageIndex3].text}</p>
                  </li>
                  {/* <br />
                    <li>
                      <img src="https://unsplash.it/200/200" width="200" />
                      <p>image #4</p>
                    </li> */}
                </ul>
              </div>
            </div>
          </div>
        )}
      </p>
    </div>
  );
};

export default Login;
