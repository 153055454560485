import './HistoryVoteCard.css'

import trashIcon from './trash-solid 1.svg'
import deleteBallotPopUp from './delete-ballot-popup.svg'
import strings from '../../../locales/ro.json'

import { useState } from 'react'
import StandardPopup from '../../Global/StandardPopup/StandardPopup'

const HistoryVoteCard = ({ title, description}) => {

    const [openPopUp, setOpenPopUp] = useState(false)

    function onTrashClick() {
        setOpenPopUp(true)
    }

    function deleteBallot() {
        console.log('deleted')

        // for debugging purposes, delete when implementing functionality
        setOpenPopUp(false)

        // TODO: delete ballot
    }

    return (
        <div className='historyVoteCardWrapper'>
            <div className="historyVoteCardTitle">{title}</div>
            <div className="historyVoteCardDescription">{description}</div>
            <div className="trashWrapper" onClick={onTrashClick}>
                <img src={trashIcon} className='trashIcon' />
            </div>
            <StandardPopup message={strings.voteHistory.popupTitle} isOpen={openPopUp} yesFcn={deleteBallot} noFcn={() => {setOpenPopUp(false)}} icon={deleteBallotPopUp}/>
        </div>
    )
}

export default HistoryVoteCard