import React from "react"
import Avatar from "../../../Avatar/Avatar"
import MemberStatusChanger from "../../../Buttons/MemberStatusChanger/MemberStatusChanger"


// this was added because I couldnt fix a bug any other way. I'm sorry
/*

statute: member's statute {junior,senior,alumni};
photoURL: url to photo
name: string to display
height: int

*/

const MemberRow = ({statute,photoURL,name,height,userUid}) => {
    return <div className="membersTableElement">
        <Avatar photoURL={photoURL} name={name} height={height}/>
        <MemberStatusChanger status={statute} name={name} userUid={userUid}/>
    </div>
}

export default MemberRow