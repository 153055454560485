import React, { useEffect, useRef, useState } from "react";
import "./CreateVoteCard.css";
import xlogo from "./x.svg";

/**

### Props
- handleOptions(required): function from parent called whenever question/optionArray changes
- className(optional): classes that apply to the wrapper div of the object
- style(optional): styles that apply to the wrapper div of the object
### Example
```jsx
const [options, setOptions] = useState({});
// ... 
<CreateVoteCard handleOptions={setOptions}/>
```

### Balot Schema
```json
{
    "title": "string",
    "question": "string",
    "options": ["string"],
    "type": "type in ['secret','open']"
}
```

### Responsibilities
- title: Parent;
- question: Child(this);
- options: Child(this);
- type: Parent;

*/

const CreateVoteCard = ({ className, style, handleOptions }) => {
  const abstainText = "Abtinere";
  const questionPlaceholderText = "Insereaza o intrebare...";
  const addOptionText = "Adauga o optiune...";
  const removeOptionText = "Sterge optiunea...";
  const inputRefs = useRef([]);
  const [optionArray, setOptionArray] = useState([]);

  useEffect(() => {
    optionArray.map((item, i) => {
      inputRefs.current[i].value = item;
      return "";
    });
  }, [optionArray]);


  const addOptionOnLoseFocus = (event) => {
    if (event.target.value === "") return;
    let initialOptionValue = event.target.value;
    let finalOptionValue =
      initialOptionValue.charAt(0).toUpperCase() + initialOptionValue.slice(1);
    handleOptions((options) => {
      let newOptions = options;
      newOptions.options = newOptions.options
        ? [...newOptions.options, finalOptionValue]
        : [finalOptionValue]; // options can be undefined, we check for it

      setOptionArray((prevArray) => {
        let newArray = [];
        newArray = [...prevArray, finalOptionValue];
        event.target.value = "";
        return newArray;
      });
      return JSON.parse(JSON.stringify(newOptions)); // Deep copy to update state
    });
    // console.log("mega testul")
  };

  const editOptionOnLoseFocus = (event, index) => {
    handleOptions((options) => {
      let newOptions = options;
      newOptions.options = [...newOptions.options];

      if (event.target.value) newOptions.options[index] = event.target.value;
      else newOptions.options.splice(index, 1); // If blank we just remove
      setOptionArray((prevArray) => {
        return newOptions.options;
      });
      return JSON.parse(JSON.stringify(newOptions)); // Deep copy to update state
    });
  };

  const handleOptionKeyDown = (event, index) => {
    if (event.keyCode === 13) {
      // Press enter
      if (index === -1) {
        // When adding new element

        if (event.target.value === "") return;
        let initialOptionValue = event.target.value;
        let finalOptionValue =
          initialOptionValue.charAt(0).toUpperCase() +
          initialOptionValue.slice(1);
        handleOptions((options) => {
          let newOptions = options;
          newOptions.options = newOptions.options
            ? [...newOptions.options, finalOptionValue]
            : [finalOptionValue]; // options can be undefined, we check for it

          setOptionArray((prevArray) => {
            let newArray = [];
            newArray = [...prevArray, finalOptionValue];
            event.target.value = "";
            return newArray;
          });
          return JSON.parse(JSON.stringify(newOptions)); // Deep copy to update state
        });
      } // When we have to edit an actual item
      else
        handleOptions((options) => {
          let newOptions = options;
          newOptions.options = [...newOptions.options];

          if (event.target.value)
            newOptions.options[index] = event.target.value;
          else newOptions.options.splice(index, 1); // If blank we just remove
          setOptionArray((prevArray) => {
            return newOptions.options;
          });
          return JSON.parse(JSON.stringify(newOptions)); // Deep copy to update state
        });
    }
  };

  return (
    <div className={`voteFormBG ${className}`} style={style}>
      <div className="voteHBar" /> {/* position is absolute */}
      <div className="voteQuestion">
        <input
          type="text"
          className="questionInput"
          placeholder={questionPlaceholderText}
          onChange={(event) => {
            handleOptions((options) => {
              let newOptions = options;
              newOptions.question = event.target.value;
              return JSON.parse(JSON.stringify(newOptions));
            });
          }}
        />
      </div>
      <div className="voteOptions">
        {optionArray.map((item, i) => {
          return (
            <div key={i}>
              <input type="radio" name="option" />
              <input
                type="text"
                className="optionInput"
                placeholder={removeOptionText}
                onKeyDown={(event) => {
                  handleOptionKeyDown(event, i);
                }}
                onBlur={(event) => {
                  editOptionOnLoseFocus(event, i);
                }}
                ref={(el) => (inputRefs.current[i] = el)} // Obtain ref array
              />
              <img
                src={xlogo}
                alt="close"
                onClick={() => {
                  handleOptionKeyDown(
                    { keyCode: 13, target: { value: "" } },
                    i
                  ); // Hardcoding an "enter" key press event
                  // with empty value target => deleting element
                }}
              />
            </div>
          );
        })}
        <div className="optionInput1">
          <input type="radio" name="option" />
          <input
            type="text"
            className="optionInput"
            placeholder={addOptionText}
            onKeyDown={(event) => {
              handleOptionKeyDown(event, -1);
            }}
            onBlur={(event) => {
              addOptionOnLoseFocus(event);
            }}
          />
        </div>
        <div className="voteOption">
          <input type="radio" name="option" disabled={true} />
          <input
            type="text"
            className="optionInput disabled"
            value={abstainText}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateVoteCard;
