import React from "react";

import { UserAuth } from "../context/AuthContext";

import strings from "../locales/ro.json"
import "./Vote.css";
import Vote from "./Vote";
import Results from "./Results";
import EroarePage from "./errorpage";
import FloatingButton from "../components/Buttons/FloatingButton/FloatingButton";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

import alreadyVoted from "./dejaAiVotat.svg";
import noVotes from "./noVotes.svg";
import noUserStatus from "./noUserStatus.svg";
import LoadingCircle from "../components/Global/LoadingCircle";
import requestHandler from "../utils/requestHandler";

const PublicRouting = () => {
  const { user, logOut } = UserAuth();
  const myAuthToken = `Bearer ${user.accessToken}`;
  const [ballot, setBallot] = React.useState(null);
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [adminButtonFunction, setAdminButtonFunction] = React.useState(() => {
    console.log("Something has gone wrong!");
  });
  const [adminButtonIcon, setAdminButtonIcon] = React.useState("pen");
  const [responseStatus, setResponseStatus] = React.useState(0);
  const [userStatuteCanVote, setUserStatuteCanVote] = React.useState(undefined);
  const NO_BALLOT_STATUS_CODE = 404;
  const navigate = useNavigate();

  const logOutButtonFunction = async () => {
    await logOut();
  };
  React.useEffect(() => {
    if (user) {
      const ballotData = async () => {
        try {
          // console.log(`https://${process.env.REACT_APP_BACKEND_IP}/ballot`);
          // const response = await fetch(
          //   `https://${process.env.REACT_APP_BACKEND_IP}/ballot`,
          //   {
          //     method: "GET",
          //     headers: {
          //       "Content-Type": "application/json",
          //       Authorization: myAuthToken,
          //     },
          //   }
          // );
          // const result = await response.json();
          const result = await requestHandler("ballot","GET",myAuthToken);
          setBallot(result);
          
          console.log(result)
          // setResponseStatus(response.status);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
        //  finally {
        //   setLoading(false);
        // }
      };
      ballotData();
      const checkisAdmin = async () => {
        try {
          const result = await requestHandler(`users/${user.email.split("@")[0]}`,"GET",myAuthToken);
          if (result.isAdmin) {
            setIsAdmin(result.isAdmin);
          }
          if (result.statute) {
            setUserStatuteCanVote(
              result.statute == "senior" ||
                result.statute == "junior" ||
                result.statute == "alumni"
            );
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
        //  finally {
        //   setLoading(false);
        // }
      };
      checkisAdmin();
    }
  }, [user]);

  const closeBallot = async () => {
    try {
      // const response = await fetch(
      //   `https://${process.env.REACT_APP_BACKEND_IP}/ballot`,
      //   {
      //     method: "DELETE",
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: myAuthToken,
      //     },
      //   }
      // );
      const result = await requestHandler("ballot","DELETE",myAuthToken);
      setBallot(result);
      navigate("/");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const [currentPage, setCurrentPage] = React.useState();
  const [hasVoted, setHasVoted] = React.useState(false);
  React.useEffect(() => {
    if (ballot) {
      if (ballot.state == "open") {
        setCurrentPage("vote");
        setAdminButtonFunction(() => {
          return () => {
            navigate("/admin");
          };
        });
        setAdminButtonIcon("pen");
      } else if (ballot.state == "closed") {
        setCurrentPage("results");
        setAdminButtonIcon("check");
        setAdminButtonFunction(() => {
          return async () => {
            await closeBallot();
            window.location.reload(false);
          };
        });
      } else {
        setAdminButtonFunction(() => {
          console.log("aici iara");
          return () => {
            navigate("/admin");
          };
        });
        setAdminButtonIcon("plus");
      }
      if (user.email && ballot.voters) {
        ballot.voters.forEach((voter) => {
          if (user.email.split("@")[0] == voter) setHasVoted(true);
        });
      }
    }
  }, [ballot]);

  return (
    <div>
      <div className="ver"> {strings.global.version} </div>
      {userStatuteCanVote === true && (
        <div>
          {currentPage == "vote" && !hasVoted && <Vote ballot={ballot} />}
          {currentPage == "results" && <Results ballot={ballot} />}
          {ballot.message === "No ballot found" && (
            <EroarePage
              img={noVotes}
              text={
                strings.errors.novote
              }
            />
          )}
          {hasVoted && currentPage != "results" && (
            <EroarePage
              img={alreadyVoted}
              text={
                strings.errors.alreadyvoted
              }
            />
          )}
        </div>
      )}
      {userStatuteCanVote === false && (
        <EroarePage
          img={noUserStatus}
          text={
            strings.errors.nostatute
          }
        />
      )}
      {userStatuteCanVote === undefined && (
        <LoadingCircle/>
      )}
      {isAdmin && (
        <FloatingButton
          typeOfButton={adminButtonIcon}
          onClickFcn={adminButtonFunction}
        />
      )}
      <FloatingButton
        typeOfButton={"logOut"}
        onClickFcn={logOutButtonFunction}
        alignVertical="top"
        text={"Sign Out"}
      />
    </div>
  );
};

const socket = new WebSocket(`wss://${process.env.REACT_APP_BACKEND_IP}`);

socket.onmessage = function(event) {
  const data = JSON.parse(event.data);
  if (data.type === 'ballotCreated') {
    window.location.reload();
  }
};

export default PublicRouting;
