import React from "react";
import "./PopUpResponseButton.css";

const PopUpResponseButton = ({ type, text, onClick }) => {
    let buttonClasses = "defaultButton ";
    let buttonText = text;

    if (
        type === "cancel" ||
        type === "ma mai gandesc" ||
        type === "invalideaza"
    ) {
        buttonClasses += "cancelButton";
    } else if (
        type === "yes" ||
        type === "da" ||
        type === "inchide si afiseaza"
    ) {
        buttonClasses += "yesButton";
    }

    return (
        <button className={buttonClasses} onClick={onClick}>
            <span>{buttonText}</span>
        </button>
    );
};

export default PopUpResponseButton;
