export default async function requestHandler(path, method, authorization, body = null) {
    if(body==null) {
        const response = await fetch(
      `https://${process.env.REACT_APP_BACKEND_IP}/${path}`,
      {
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: authorization,
        },
      }
    );
    // console.log("testtest")
    const result = await response.json();
    return result;
}
    else {
        const response = await fetch(
            `https://${process.env.REACT_APP_BACKEND_IP}/${path}`,
            {
              method: method,
              headers: {
                "Content-Type": "application/json",
                Authorization: authorization,
              },
              body: JSON.stringify(body)
            }
          );
          const result = await response.json();
          return result;
        }
    

}
