import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPen,
    faUserGroup,
    faUser,
    faPlus,
    faTimes,
    faCheck,
    faArrowRightFromBracket
} from "@fortawesome/free-solid-svg-icons";
import "./FloatingButton.css";

function FloatingButton(props) {
    const { typeOfButton, onClickFcn, align="right", alignVertical="bottom", text } = props;

    const iconMap = {
        pen: faPen,
        userGroup: faUserGroup,
        user: faUser,
        plus: faPlus,
        xMark: faTimes,
        check: faCheck,
        logOut: faArrowRightFromBracket
    };
    console.log(iconMap[typeOfButton])
    const selectedIcon = iconMap[typeOfButton] || faPen;

    const customStyling = {}
    if(align == "right") {
        customStyling.right = "28px";
    } else if (align =="left") {
        customStyling.left = "28px";
    }
    if(alignVertical == "bottom") {
        customStyling.bottom = "28px";
    } else if (alignVertical =="top") {
        customStyling.top = "28px";
    }
    if (window.innerWidth > 768) {
        return (
            <button style={customStyling} className={text?'floatingButton':'floatingButton floatingButtonNoText'} onClick={onClickFcn}>
                
                <div className="floatingButtonIconPlaceHolder">
                <FontAwesomeIcon
                    className="floatingButtonIcon"
                    icon={selectedIcon}
                />
                </div>
                {text && <div className="floatingButtonSignOutTextPlaceHolder">
                    <div className="floatingButtonSignOutText">{text}</div>
                </div>} 
            </button>
        );
    } else {
        return (
            <button style={customStyling} className={text?'floatingButton':'floatingButton floatingButtonNoText'} onClick={onClickFcn}>
                {text && <div className="floatingButtonSignOutTextPlaceHolder">
                    <div className="floatingButtonSignOutText">{text}</div>
                </div>} 
                {!text && <div className="floatingButtonIconPlaceHolder">
                <FontAwesomeIcon
                    className="floatingButtonIcon"
                    icon={selectedIcon}
                />
                </div>
                }
            </button>
        );
    }
}

export default FloatingButton;
