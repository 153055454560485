import React from "react";
import strings from "../locales/ro.json"
import CreateVote from "./CreateVote";
import EditVote from "./EditVote";
import { UserAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import FloatingButton from "../components/Buttons/FloatingButton/FloatingButton";
import LoadingCircle from "../components/Global/LoadingCircle";
import requestHandler from "../utils/requestHandler";

const AdminRoutes = () => {
  const { user } = UserAuth();
  const myAuthToken = `Bearer ${user.accessToken}`;
  const [ballot, setBallot] = React.useState();
  const navigate = useNavigate()

  const toUserManagementFunction = () => {
    navigate("/usermanagement");
  }
  const backToPublicFunction = () => {
    navigate("/");
  }

  React.useEffect(() => {
    if (user) {
      const ballotData = async () => {
        try {
          const result = await requestHandler("ballot","GET",myAuthToken)
          setBallot(result);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
        //  finally {
        //   setLoading(false);
        // }
      };
      ballotData();
    }
  }, [user]);
  const [currentPage, setCurrentPage] = React.useState();
  React.useEffect(() => {
    if(ballot) {
        console.log(ballot)
        if (ballot.state == "closed") {
          setCurrentPage("edit")
        } else if (ballot.state == "open") {
          setCurrentPage("edit")
        } else {
            setCurrentPage("create")
        }    
    }
    // if(!ballot) {
    //     setCurrentPage("create")
    // }
  }, [ballot]);
  return (
    <div>
      {!ballot && <LoadingCircle/>}
      {currentPage == "create" && <CreateVote />}
      {currentPage == "edit" && <EditVote ballot={ballot} setBallot={setBallot} />} 
      <FloatingButton typeOfButton={"userGroup"} onClickFcn={toUserManagementFunction}/>
      <FloatingButton align={"left"} typeOfButton={"xMark"} onClickFcn={backToPublicFunction}/>
      <div className="ver"> {strings.global.version} </div>
    </div>
  );
};

export default AdminRoutes;
