import React from "react";
import { uidToName } from "../utils/uidToName";

import { UserAuth } from "../context/AuthContext";
import SearchBar from "../components/MemberManagement/SearchBar/SearchBar";
import MembersTable from "../components/MemberManagement/MembersTable/MembersTable";
import strings from "../locales/ro.json"

import "./UserManagement.css";
import HeaderElement from "../components/HeaderElement/HeaderElement";
import FloatingButton from "../components/Buttons/FloatingButton/FloatingButton";
import { useNavigate } from "react-router-dom";
import LoadingCircle from "../components/Global/LoadingCircle";
import requestHandler from "../utils/requestHandler";


const UserManagement = () => {
  const { user } = UserAuth();

  // const users = [
  //   { statute: "junior", uid: "theodora.spoici-rotaru" },
  //   { statute: "junior", uid: "biancsa.alexe" },
  //   { statute: "junior", uid: "biancda.alexe" },
  //   { statute: "alumni", uid: "robert.stanca" },
  //   { statute: "junior", uid: "bianca.alexe" },
  //   { statute: "senior", uid: "biancfa.alexe" },
  //   { statute: "junior", uid: "bianca.alexe" },
  // ];
  const [users, setUsers] = React.useState([]);
  const [gotData,setGotData] = React.useState(false);
  const navigate = useNavigate()

  const backFunction = () => {
    navigate("/admin")
  }

  React.useEffect(() => {
    console.log(user)
    if (user.accessToken) {
      const userData = async () => {
        try {
          const myAuthToken =
          `Bearer ${user.accessToken}`;
           console.log(myAuthToken)
           console.log(user)
          const result = await requestHandler("users","GET",myAuthToken);
          result.forEach((element) => { element["name"] = uidToName(element["uid"]); });
          setUsers(result);
          setGotData(true);
          console.log(result);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
        //  finally {
        //   setLoading(false);
        // }
      };
      userData();
    }
  }, [user]);

  React.useEffect(() => {
    setFilteredUsers(users);
  }, [users]);

  const [filteredUsers, setFilteredUsers] = React.useState(users);

  const handleFilterChange = (event) => {
    // setFilter(event.target.value);
    setFilteredUsers((prevArr) => {
      let newArr = [];

      newArr = users.filter((element) => {
        return element["uid"].includes(event.target.value);
      });
      return newArr;
    });
  };
  return (
    <div>
      <div className="userManagementContainer">
        <HeaderElement headerText={strings.usermanagement.title} />
        <SearchBar
          // baseList={users}
          // setFilteredList={setFilteredUsers}
          // compareAgainst={"uid"}
          handleChange={handleFilterChange}
        />
        {filteredUsers.length !== 0 && <MembersTable users={filteredUsers} />}
        {filteredUsers.length === 0 && <LoadingCircle/>}
        <FloatingButton typeOfButton={"xMark"} onClickFcn={backFunction} />
      </div>
    </div>
  );
};

export default UserManagement;
