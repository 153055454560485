import React, { useState } from 'react';
import './ResultsCard.css';

import './ProgressBar/ProgressBar'
import ProgressBar from './ProgressBar/ProgressBar';
import arrow from './Arrow.svg'


/*
Props:
    total: Total of people who voted here
    name: The name of the voting card, is usually "Da", "Nu", "Abtinere"
    votes: The amount of people who voted on this card
    children: The people who voted(syntax: <ResultsCard>here</ResultsCard>)
*/

const ResultsCard = ({ total,name,voteCount,children }) => {
    const value = (Math.trunc(voteCount/total*100*100))/100;

    const [isExpanded, setIsExpanded] = useState(false);


    const handleExpandClick = () => {
        setIsExpanded(!isExpanded);
    };


    const voteCountText = "voturi"
    const seeMoreText = "Vezi mai mult"
    const seeLessText = "Vezi mai putini"
    return (
        <div className="resultsCardbBg"  style={value>50 ? {border:"solid 3px var(--green)"}: {}}>
            <div className="resultsCardTitleProc">
                <div className="resultTextBlack">{name}</div>
                <div className="resultTextBlack">{value}%</div>
            </div>
            <ProgressBar value={value} />
            <div className="resultVoteNumber" data-testid="resultsVoteNumber" >
                {voteCount} {voteCountText}
            </div>
            {isExpanded?children:""}
            {
                children ?
                    (
                        
                        <div className="resultsSeeMore" onClick={handleExpandClick}>
                            <img src={arrow} className={isExpanded ? "resultsReverseArrow" : ""} alt='' />
                            <div className="resultTextBlack" style={{cursor:"pointer"}}>{isExpanded?seeLessText:seeMoreText}</div>
                        </div>
                    )
                    : (<></>)
            }
        </div>
    );
};

export default ResultsCard;