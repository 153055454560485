import React from "react";

import "./VotingCard.css";
import HeaderElement from "../../HeaderElement/HeaderElement";
import VoteType from "./VoteType/VoteType";
import VoteButton from "../../Buttons/VoteButton/VoteButton";
import SendVote from "../../Buttons/SendVote/SendVote";
import popupicon from "./popup.svg";
import StandardPopup from "../../Global/StandardPopup/StandardPopup";

/*
 This is the voting card component, that contains the title, question, options of a ballot and a button for the user to vote

 Props:

 ballot = a ballot object that has the title,question, type and options fields populated according to the costelDB documentation
*/

const VotingCard = ({ ballot = { options: ["test1", "test2"] }, canVote=true , authToken}) => {
  const [currentVote, setCurrentVote] = React.useState(-1);

  const [popupOpen, setPopupOpen] = React.useState(false);

  const [shouldRefresh, setShouldRefresh] = React.useState(false);

  React.useEffect(() => {
    if (shouldRefresh ==true)
      window.location.reload(true);
  }, [shouldRefresh])

  async function sendVote() {
    try {
      const response = await fetch(
        `https://${process.env.REACT_APP_BACKEND_IP}/vote`,
        {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'Authorization': authToken,
          },
          body: JSON.stringify({option: currentVote}),
        }
      );
      // const result = await response.json();
      setShouldRefresh(true);
      // console.log(result)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const messageOp = `Esti sigur ca vrei sa trimiti acest vot? `;
  const messageOp2 = `Optiunea aleasa: "${ballot.options[currentVote]}"` ;

  return (
    <div className="votingCardContainer">
      <StandardPopup message={messageOp} message2={messageOp2} icon={popupicon} yesFcn={async () => {setPopupOpen(false); await sendVote();}} noFcn={() => {setPopupOpen(false)}} isOpen={popupOpen}/>
      <HeaderElement headerText={ballot.title} />
      <div className="ballotQuestion">{ballot.question}</div>
      <div className="onlyOneOption">Exista o singura varianta de raspuns</div>
      <VoteType voteType={ballot.type} />
      {ballot.options && <div className="voteButtonsContainer">
        {ballot.options.map((text, optionNumber) => {
          return (
            <VoteButton
              text={text}
              onClick={() => {
                if(canVote)setCurrentVote(optionNumber);
              }}
              isPressed={optionNumber == currentVote}
            />
          );
        })}
      </div>}
      <div className="sendVoteButtonContainer">
        {currentVote !== -1 && (
          <SendVote
            onClickFunction={() => {
              setPopupOpen(true);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default VotingCard;
