import HeaderElement from "../components/HeaderElement/HeaderElement"
import HistoryVoteCard from "../components/Voting/HistoryVoteCard/HistoryVoteCard"
import strings from "../locales/ro.json"

import './VoteHistory.css'

const VoteHistory = () => {

    return (
        <div className="voteHistoryPageWrapper">
            <div className="voteHistoryGrid">
                <HeaderElement headerText={strings.voteHistory.title} />
                <div className='voteCardWrapper'>
                    <HistoryVoteCard title='Parere Boris' description="Sunteti de acord ca Boris este cel mai jmecher motan?"/>
                    <HistoryVoteCard title='Parere Boris' description="Sunteti de acord ca Boris este cel mai jmecher motan?"/>
                    <HistoryVoteCard title='Parere Boris' description="Sunteti de acord ca Boris este cel mai jmecher motan?"/>
                    <HistoryVoteCard title='Parere Boris' description="Sunteti de acord ca Boris este cel mai jmecher motan?"/>
                </div>
            </div>
        </div>

    )
}

export default VoteHistory