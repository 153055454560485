import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import "./SendToLCButton.css";
/**
### Props
- onClick(required): parent function that triggers when button is clicked
- className(optional): classes that apply to the wrapper div of the object
- style(optional): styles that apply to the wrapper div of the object

*/
const SendToLCButton = ({onClick, className, style}) => {
    return (
        <button onClick={onClick} className={`sendButton ${className}`} style={style}>
            <FontAwesomeIcon className="sendButtonIcon" icon={faCheck} />
            <span className="sendButtonLabel">Trimite votul către LC</span>
        </button>
    );
};

export default SendToLCButton;
