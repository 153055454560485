import React from "react";
import "./MemberStatusButton.css";

/*

  This is the actual pressable button component that is used in the StatusChanger

  Props

  status : a member's status {"junior","senior","alumni"}. Default is Error.
  OnClickFunction: function to execute on click. no default

*/

function MemberStatusButton({status, onClickFunction}) {
  
    let memberStatus = status
    let styling = {}
    let buttonText;
    if(memberStatus==="junior") {
        buttonText = "Junior";
        styling.color = "var(--green)";
        styling.borderColor="var(--green)";
    } else if (memberStatus==="senior") {
        buttonText="Senior";
        styling.color = "var(--eestec-red)";
        styling.borderColor="var(--estec-red)";
    } else if (memberStatus==="alumni") {
        buttonText="Alumni";
        styling.color = "var(--burgundy)";
        styling.borderColor="var(--burgundy)";
    } else {
        buttonText="Error!";
        styling.color = "var(--dark-grey)";
        styling.borderColor="var(--dark-grey)";
    }

    return (
    <button
      className="memberStatusButton"
      style={styling}
      onClick={onClickFunction}
      data-testid="memberStatusButton"
    >
      {buttonText}
    </button>
  );
}

export default MemberStatusButton;
