import React from "react";

import "./VoteType.css";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";


/*
This component is the icon and small bit of text that designates if this is a secret or open vote.

Props

voteType = secret || public 
*/
const VoteType = ({ voteType }) => {
  return (
    <div className="voteTypeContainer">
      {voteType == "secret" ? (
        <svg
          width="20"
          height="19"
          viewBox="0 0 20 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.06 11.0021C13.2 11.0021 11.64 12.3321 11.24 14.1021C10.29 13.6921 9.42 13.8021 8.76 14.0921C8.35 12.3121 6.79 11.0021 4.94 11.0021C2.77 11.0021 1 12.7921 1 15.0021C1 17.2121 2.77 19.0021 4.94 19.0021C7 19.0021 8.68 17.3821 8.84 15.3221C9.18 15.0821 10.07 14.6321 11.16 15.3421C11.34 17.3921 13 19.0021 15.06 19.0021C17.23 19.0021 19 17.2121 19 15.0021C19 12.7921 17.23 11.0021 15.06 11.0021ZM4.94 17.8621C3.38 17.8621 2.13 16.5821 2.13 15.0021C2.13 13.4221 3.39 12.1421 4.94 12.1421C6.5 12.1421 7.75 13.4221 7.75 15.0021C7.75 16.5821 6.5 17.8621 4.94 17.8621ZM15.06 17.8621C13.5 17.8621 12.25 16.5821 12.25 15.0021C12.25 13.4221 13.5 12.1421 15.06 12.1421C16.62 12.1421 17.88 13.4221 17.88 15.0021C17.88 16.5821 16.61 17.8621 15.06 17.8621ZM20 8.50207H0V10.0021H20V8.50207ZM13.53 0.632069C13.31 0.142069 12.75 -0.117931 12.22 0.0520693L10 0.792069L7.77 0.0520693L7.72 0.0420693C7.19 -0.107931 6.63 0.172069 6.43 0.682069L4 7.00207H16L13.56 0.682069L13.53 0.632069Z"
            fill="#444444"
          />
        </svg>
      ) : (
        <RemoveRedEyeIcon style={{ color: "var(--dark-gray)" }} />
      )}
      <div className="voteTypeText">{voteType === "secret" ? "Vot secret" : "Vot deschis"}
      </div>
    </div>
  );
};

export default VoteType;
