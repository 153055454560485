import React from 'react'

import "./HeaderElement.css"


/**

### Props
- headerText(required): Text to be displayed as a title (Or as a placeholder 
    if inputHandler is defined)
- inputHandler(optional): function from parent called whenever title changes.
Transforms the header object into input field.

### Example
```jsx
const [options, setOptions] = useState({});
// ... 
<HeaderElement headerText="..." inputHandler={setOptions} />
```

### Balot Schema
```json
{
    "title": "string",
    "question": "string",
    "options": ["string"],
    "type": "type in ['secret','open']"
}
```

### Responsibilities
- title: Child(this);
- question: Parent;
- options: Parent;
- type: Parent;

*/

const HeaderElement = ({ inputHandler, headerText }) => {
    //TODO: add state handling for this input
    
    //you should never ever add a 1px div
    //HOWEVER...
    return inputHandler?
    (<div className='headerElement'><input
        className='headerInput'
        placeholder={headerText}
        onChange={(event) => {
            inputHandler((prev) => {
                let newItem = prev;
                newItem.title = event.target.value;
                return JSON.parse(JSON.stringify(newItem));
            })
        }}/>
        <div className='redHeaderLine'></div>
        </div>
    ):
    (<h1 className='headerElement'>{headerText}<div className='redHeaderLine'></div></h1>)

}

export default HeaderElement