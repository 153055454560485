import React from "react";
import { uidToName } from "../utils/uidToName";

import strings from "../locales/ro.json"
import { UserAuth } from "../context/AuthContext";
import VotingCard from "../components/Voting/VotingCard/VotingCard";
import BarChartIcon from "@mui/icons-material/BarChart";
import "./EditVote.css";
import { Modal } from "@mui/material";
import PopUpResponseButton from "../components/Buttons/PopUpResponseButton/PopUpResponseButton";
import popupicon from "./createVotPopup.svg";
import { useNavigate } from "react-router-dom";
import StandardPopup from "../components/Global/StandardPopup/StandardPopup";
import requestHandler from "../utils/requestHandler";

const EditVote = ({ballot,setBallot}) => {
  const { user } = UserAuth();
  console.log(user.accessToken);
  const myAuthToken = `Bearer ${user.accessToken}`;
  // const [gotData, setGotData] = React.useState(false);
  // const [ballot, setBallot] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [openInvalidationPopup, setOpenInvalidationPopup] =
    React.useState(false);
  const [openClosingPopup, setOpenClosingPopup] = React.useState(false);
  const navigate = useNavigate();

  const closeBallot = async () => {
    try {
      const result = await requestHandler("ballot","PATCH",myAuthToken)
      setBallot(result);
      console.log(result);
      navigate("/");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const deleteBallot = async () => {
    try {
      const result = await requestHandler("ballot","DELETE",myAuthToken)
      setBallot(result);
      console.log(result);
      window.location.reload(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const [seniorsArr,setSeniorsArr] = React.useState([])
  const [juniorsArr,setJuniorsArr] = React.useState([])
  const [alumniArr,setAlumniArr] = React.useState([])

  function addElementToStateArr(setter,element) {
    setter((prevArr) => {
      const newArr = [];
      if(prevArr != undefined)
        prevArr.forEach((element) => {
          newArr.push(element)
        })
      newArr.push(element)
      return(newArr)
    })
  }

  React.useEffect(() => {
    if(ballot != null) {
      console.log(ballot)
      ballot.voters.map((voter, index) => {
        const statute = ballot.statutes[index]
        if(statute === "senior")
          addElementToStateArr(setSeniorsArr, uidToName(voter))
        if(statute === "junior")
          addElementToStateArr(setJuniorsArr, uidToName(voter))
        if(statute === "alumni")
          addElementToStateArr(setAlumniArr, uidToName(voter))
        console.log(seniorsArr)
      })
    }
  },[ballot])

  return (
    <div className="">
      <Modal open={openModal}>
        <div className="voteStatusModalContainer">
          <div className="voteStatusModalHeader">Au votat deja</div>
          {ballot != null && (
            <div className="voteStatusModalCount">
              {ballot.voters.length} voturi
            </div>
          )}

          <div className="voteStatusModalVotersContainer">
            {ballot != null && <div className="voteStatusModalVoters">
              <div className="voteStatusModalVotersStatute">Seniori({seniorsArr != undefined && seniorsArr.length}):</div>
              {seniorsArr != undefined && seniorsArr.map((senior) => {
                return <p>{senior}</p>
              })}
              <div className="voteStatusModalVotersStatute">Juniori({juniorsArr != undefined && juniorsArr.length}):</div>
              {juniorsArr != undefined && juniorsArr.map((junior) => {
                return <p>{junior}</p>
              })}
              <div className="voteStatusModalVotersStatute">Alumni({alumniArr != undefined && alumniArr.length}):</div>
              {alumniArr != undefined && alumniArr.map((alumni) => {
                return <p>{alumni}</p>
              })}
            </div>
              }
          </div>
          <div className="voteStatusModalButton">
            <PopUpResponseButton
              type="cancel"
              text={"Inchide"}
              onClick={() => {
                setOpenModal(false);
              }}
            />
          </div>
        </div>
      </Modal>
      <StandardPopup message={strings.editvote.invalidatePopup} isOpen={openInvalidationPopup} yesFcn={deleteBallot} noFcn={() => {setOpenInvalidationPopup(false)}} icon={popupicon}/>
      <StandardPopup message={strings.editvote.showResultsPopup} isOpen={openClosingPopup} yesFcn={closeBallot} noFcn={() => {setOpenClosingPopup(false)}} icon={popupicon}/>
      <div className="votingContainer">
        {ballot && <VotingCard canVote={false} ballot={ballot} />}
      </div>
      <button
        className="voteStatusButton"
        onClick={() => {
          setOpenModal(true);
        }}
      >
        <BarChartIcon />
        <div>Vezi starea voturilor</div>
      </button>
      {ballot && (
        <div className="editBallotVotesCount">
          Total Voturi: {ballot.voters.length}
        </div>
      )}
      <div className="editBallotButtonsContainer">
        <PopUpResponseButton
          type={"yes"}
          text={"Inchide si afiseaza"}
          onClick={() => {
            setOpenClosingPopup(true);
          }}
        />
        <PopUpResponseButton
          type={"cancel"}
          text={"Invalideaza"}
          onClick={() => {
            setOpenInvalidationPopup(true);
          }}
        />
      </div>
    </div>
  );
};

export default EditVote;
