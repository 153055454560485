import React from "react";
import { UserAuth } from "../context/AuthContext";
import FloatingButton from "../components/Buttons/FloatingButton/FloatingButton";

const Developer = () => {
    
    const { user, logOut } = UserAuth();
    const logOutButtonFunction = async () => {
        await logOut();
    };
    return(
        <div>
            <div>Buna</div>
            <FloatingButton
                typeOfButton={"logOut"}
                onClickFcn={logOutButtonFunction}
                alignVertical="top"
                text={"Sign Out"}
            />
        </div>
    )
}

export default Developer