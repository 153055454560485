import React, { useEffect } from "react";
import "./MembersTable.css";
import MemberStatusChanger from "../../Buttons/MemberStatusChanger/MemberStatusChanger";
import Avatar from "../../Avatar/Avatar";
import MemberRow from "./MemberRow/MemberRow";
import strings from "../../../locales/ro.json"
import arrow from './arrow.svg'

/*
users: array of users according to costelDB API
*/


/*

This is the table shown on the member management page. It shows a member's name and profile pic on one column and the user's status on the other column.

Props:

users: an array of users, as according to the CostelDB API docs.

*/

function sortUsers(users)
{
  users.sort((a, b) => {
    return a.uid.localeCompare(b.uid);
  });
}

function MembersTable({ users }) {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [sortEvent, setSortEvent] = React.useState(0)
  const userSorted = React.useRef([...users])
  const usersRef = React.useRef([...users])
  const sortState = React.useRef(1)
  const arrowPos1 = React.useRef(0)
  const arrowPos2 = React.useRef(0)

  sortUsers(users)
  if(users.join(',') !== usersRef.current.join(','))
  {
      usersRef.current = [...users]
      userSorted.current = [...users]
  }

  useEffect(() => {
    sortUsers(usersRef.current)
  }, [])

  //we use this to check if we're on a mobile device
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  function sortStatus()
  {
      userSorted.current.sort((a, b) => {
        if (sortState.current === 1) {
          return a.statute.localeCompare(b.statute);
        } else {
          return b.statute.localeCompare(a.statute);
        }
      });

      arrowPos2.current = (arrowPos2.current + 180) % 360;
      sortState.current *= -1;
      setSortEvent(sortEvent + 1);

  }

  function sortUID()
  {
      userSorted.current.sort((a, b) => {
        if (sortState.current === 1) {
          return a.uid.localeCompare(b.uid);
        } else {
          return b.uid.localeCompare(a.uid);
        }
      });
      
      arrowPos1.current = (arrowPos1.current + 180) % 360;
      sortState.current *= -1;
      setSortEvent(sortEvent + 1);

  }

  React.useEffect(() => {
    

    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 768;
  return (
    <div className="membersTable">
      <div className="membersTableHead">
        <div className="membersTableHeadWrapper" onClick={sortUID}>
          <h2>{strings.usermanagement.tablehead[0]}</h2>
          <img src={arrow} className="arrowDownTable" style={{transform:`rotate(${arrowPos1.current}deg)`}}/>
        </div>
        <div className="membersTableHeadWrapper" onClick={sortStatus}>
          <h2>{strings.usermanagement.tablehead[1]}</h2>
          <img src={arrow} className="arrowDownTable" style={{transform:`rotate(${arrowPos2.current}deg)`}}/>
        </div>
      </div>
      {userSorted.current && userSorted.current.map((user) => {
        return (
          <MemberRow photoURL={user.photoURL} userUid={user.uid} name={user.name ? user.name : user.uid} height={isMobile ? 40 : 45} statute={user.statute}/>
        );
      })}
    </div>
  );
}

export default MembersTable;
