import React from "react";
import "./MemberStatusChanger.css";
import MemberStatusButton from "./MemberStatusButton/MemberStatusButton";
import popupicon from "./popupicon.svg";
import { UserAuth } from "../../../context/AuthContext";
import strings from "../../../locales/ro.json"
import StandardPopup from "../../Global/StandardPopup/StandardPopup";
import requestHandler from "../../../utils/requestHandler";

/*

  This component is the pressable button to change a member's status. It opens up three identical buttons in a container when pressed in order to change status

  Props

  status : a member's status {"junior","senior","alumni"}. Default is Error

*/

function MemberStatusChanger({ status, name , userUid}) {
  const [memberStatus, setMemberStatus] = React.useState(status);
  const [isChanging, setIsChanging] = React.useState(false);
  const [popupOpen, setPopupOpen] = React.useState(false);
  const [popupFunction, setPopupFunction] = React.useState(() => {});
  const [nextStatute, setNextStatute] = React.useState("Something has gone wrong!");
  const {user} = UserAuth();

  const handleClickOutsideMemberStatus = (event) => {
    if (!event.target.closest('.memberStatusButtonContainer')) {
      setIsChanging((prevIsChanging) => false);
    }
  };

  function toggleChanging() {
    setIsChanging((prevIsChanging) => !prevIsChanging);
  }
  const myAuthToken = `Bearer ${user.accessToken}`
  async function setStatuteInBackend(newStatute) {
    try {
      const result = await requestHandler(`users/${userUid}`,"GET",myAuthToken)
      const newUser = {...result,statute:newStatute}
      await requestHandler(`users/${userUid}`,"PATCH",myAuthToken,{user:newUser})
    } catch (error) {
      console.log(error);
    }
  }

  let option = `${name} ${status} -> ${nextStatute} `

  React.useEffect(() => {
    setMemberStatus(status);
  }, [status]);

  React.useEffect(() => {
      document.body.addEventListener('click', handleClickOutsideMemberStatus);
      return () => {
          document.removeEventListener('click', handleClickOutsideMemberStatus, true);
      };
  }, []);

  return (
    <div>
      <StandardPopup isOpen={popupOpen} icon={popupicon} message={strings.usermanagement.changePopup} message2={option} yesFcn={popupFunction} noFcn={() => {setIsChanging(false);setPopupOpen(false)}}/>
      <div className="memberStatusButtonContainer">
        <MemberStatusButton
          status={memberStatus}
          onClickFunction={toggleChanging}
        />
        {isChanging && (
          <div className="memberStatusButtonChangeContainer" data-testid='memberStatusButtonsContainer'>
            <MemberStatusButton
              status={"junior"}
              onClickFunction={() => {
                setPopupFunction((prevfcn) => {
                  return () => {
                    setMemberStatus("junior");
                    setStatuteInBackend("junior");
                    setIsChanging(false);
                    setPopupOpen(false);
                  };
                });
                setNextStatute("junior");
                setPopupOpen(true);
                
              }}
            />
            <MemberStatusButton
              status={"senior"}
              onClickFunction={() => {
                setPopupFunction((prevfcn) => {
                  return () => {
                    setMemberStatus("senior");
                    setStatuteInBackend("senior");
                    setIsChanging(false);
                    setPopupOpen(false);
                  };
                });
                setNextStatute("senior");
                setPopupOpen(true);
              }}
            />
            <MemberStatusButton
              status={"alumni"}
              onClickFunction={() => {
                setPopupFunction((prevfcn) => {
                  return () => {
                    setMemberStatus("alumni");
                    setStatuteInBackend("alumni");
                    setIsChanging(false);
                    setPopupOpen(false);
                  };
                });
                setNextStatute("alumni");
                setPopupOpen(true);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default MemberStatusChanger;
