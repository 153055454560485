import React from 'react';
import './VoteButton.css';

/*
props:
    text: the text of the button
    isPressed: callee responsibility to say if pressed or not
    onClick: function that will be executed when button is clicked
    style: for manual margination and button sizes if ever needed

*/

const VoteButton = ({ text, isPressed, onClick, style }) => {
    return (
        <button className={`voteButton ${isPressed ? "votePressed" : ""}`} style={style} onClick={onClick}>
            <div className={`voteText ${isPressed ? "voteTextPressed" : ""}`}>
                {text}
            </div>
        </button>
    );
}

export default VoteButton;
