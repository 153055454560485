import React, { useEffect, useState } from 'react';
import './ProgressBar.css';

const ProgressBar = ({value}) => {
    const [firstLoad, setFirstLoad] = useState(true);
    useEffect(() => {
      setFirstLoad(false);
    }, [value]);
    return (
        <div class="result-skill-bar">
          {
            firstLoad ?
            (<div class="result-skill-per"></div>):
            (<div class="result-skill-per" style={{width: `${value}%`}}></div>)
          }
        </div>
    )

}

export default ProgressBar;
